





































































import { defineComponent } from "@vue/composition-api";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import { isNasabah } from "@/apps/accounts/modules/store";
import { listVM } from "../modules/stores/setoran";

export default defineComponent({
  name: "SetoranList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
    MonthSelect: () =>
      import("@/apps/core/components/MonthSelect.vue"),
    BasicSelect: () =>
      import("@/apps/core/components/BasicSelect.vue"),
    SetoranListBox: () => import("@/apps/tabungan/components/SetoranListBox.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const composition = useInfiniteScrollList(listVM);
    const classContent = isNasabah.value? "column no-padding is-full":"column is-full"
    return {
      // Data
      classContent,

      // Computed
      isNasabah,

      // Composition
      ...composition,
    };
  },
});
